import Hillary101 from '../../../media/fasad/Hillary/10 (1).webp'
import Hillary102 from '../../../media/fasad/Hillary/10 (2).webp'
import Hillary103 from '../../../media/fasad/Hillary/10 (3).webp'
import Hillary104 from '../../../media/fasad/Hillary/10 (4).webp'
import Hillary105 from '../../../media/fasad/Hillary/10 (5).webp'
import Hillary106 from '../../../media/fasad/Hillary/10 (6).webp'
import Hillary107 from '../../../media/fasad/Hillary/10 (7).webp'
import Hillary108 from '../../../media/fasad/Hillary/10 (8).webp'
import Hillary109 from '../../../media/fasad/Hillary/10 (9).webp'
import Hillary1010 from '../../../media/fasad/Hillary/10 (10).webp'

const Hillary10images =[
Hillary101,Hillary102,Hillary103,Hillary104,Hillary105,Hillary106,Hillary107,Hillary108,Hillary109,Hillary1010
]

export default Hillary10images;