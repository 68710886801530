import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logosumban from "../../media/Logo.png";

const aboutsumban = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="about" className="about-sumban">
      <div className="containerlogo">
        <div className="logosumban">
          <img className="gambarsumban" src={logosumban} alt="logo sumban" />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>Smart City : Live, Work, Play</h1>
        </div>
        <div className="desk">
          Sumarecon Bandung adalah tempat tinggal modern yang indah dengan
          lingkungan yang menyenangkan. Di dalamnya terdapat Summarecon Mall
          Bandung, yang memiliki pusat pendidikan dan rekreasi. Cluster Emily,
          yang menggabungkan konsep modern dan alam, memiliki pemandangan danau
          di Sumarecon Bandung. Selain itu, Summarecon Bandung memiliki
          Summarecon Teknopolis, tempat industri ICT (Information,
          Communication, and Technology) dapat berkumpul untuk mengembangkan
          kegiatan kreatif dalam lingkungan yang ramah lingkungan.
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
