import Fasilitas1 from '../../../src/media/Fasilitas/fasilitas (1).webp'
import Fasilitas2 from '../../../src/media/Fasilitas/fasilitas (2).webp'
import Fasilitas3 from '../../../src/media/Fasilitas/fasilitas (3).webp'
import Fasilitas4 from '../../../src/media/Fasilitas/fasilitas (4).webp'
import Fasilitas5 from '../../../src/media/Fasilitas/fasilitas (5).webp'


const Fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,
]

export default Fasilitasimages;