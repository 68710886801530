import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Btariimages from "./Btari.js";
import Emilyimages from "./Emily.js";
import floraimages from "./Flora.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Florawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Flora)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Btariwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Btari)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Emilywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Emily)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {floraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Flora</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">20</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 105m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 90m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Florawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Btariimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Btari</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">21</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 162m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 120m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Btariwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Emilyimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Emily</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">30</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 190m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 147m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Emilywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
