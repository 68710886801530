import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Amandaimages from "./Amanda.js";
import Cynthiaimages from "./Cynthia.js";
import Dayanaimages from "./Deyana.js";
import Gavina9images from "./Gavina9.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Dayanawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Dayana)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Amandawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Amanda)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cynthiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Cynthia)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Gavina9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bandung%20(Genova)%20https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Dayanaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Dayana</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 66m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 54m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Dayanawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Amandaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Amanda</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 112m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 120m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Amandawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Cynthiaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Cynthia</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">12</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 105m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 121m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cynthiawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Gavina9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Genova</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="red"
                icon={faLocationDot}
              />
              <div className="namalokasi">Bandung</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">14</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 150m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 165m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Gavina9} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
