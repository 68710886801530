import React from "react";
import "./newlaunching.scss";
import Newlaunching1 from "./newlaunching1/newlaunching1.jsx";
import penawaran from "../../media/new.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const rumah = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285721946343&text=Halo%20Melissa,%20Saya%20bisa%20minta%20detail%20Promo%20terbaru%20Summarecon%20Bandung%20(Promo%20New%20Launching)https://marketing-summabandung.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="newlaunch" className="rumah1">
      <div className="judulnewlaunch">
        <div className="judul">
          <h1>New Launching Cluster Hillary</h1>
          <h2>Live Fully, Live Calmly, Conviently, Comfortably</h2>
        </div>
        <div className=" containerPenawaran">
          <div className="containercontent">
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Soft DP</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Free Kitchen Set
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Free IPL 6 Bulan
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Free Smart Door Lock
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Free Smarthome System
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Free CCTV</span>
              </div>

              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Garansi Bangunan 1 Tahun
                </span>
              </div>
            </div>
            <div className="disclaimer">*Disclaimer</div>
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
          <div className="containergambar">
            <img className="penawarangambar" src={penawaran} />
          </div>
        </div>
      </div>
      <div className="rumah-card">
        <Newlaunching1 />
      </div>
    </div>
  );
};

export default rumah;
