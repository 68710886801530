import React from "react";
import "./footer.scss";
import logo from "../../media/Logo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Summarecon Bandung</h2>
            <h3>
              Jl. Sentra Raya Barat, Rancabolang, Kec. Gedebage, Kota Bandung,
              Jawa Barat 40295
            </h3>
            <h4>Contact us : +6285721946343</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© Summarecon Bandung</div>
    </div>
  );
};

export default footer;
