import Hillary121 from '../../../media/fasad/Hillary/12 (1).webp'
import Hillary122 from '../../../media/fasad/Hillary/12 (2).webp'
import Hillary123 from '../../../media/fasad/Hillary/12 (3).webp'
import Hillary124 from '../../../media/fasad/Hillary/12 (4).webp'
import Hillary125 from '../../../media/fasad/Hillary/12 (5).webp'
import Hillary126 from '../../../media/fasad/Hillary/12 (6).webp'
import Hillary127 from '../../../media/fasad/Hillary/12 (7).webp'
import Hillary128 from '../../../media/fasad/Hillary/12 (8).webp'
import Hillary129 from '../../../media/fasad/Hillary/12 (9).webp'
import Hillary1210 from '../../../media/fasad/Hillary/12 (10).webp'
import Hillary1211 from '../../../media/fasad/Hillary/12 (11).webp'
import Hillary1212 from '../../../media/fasad/Hillary/12 (12).webp'
import Hillary1213 from '../../../media/fasad/Hillary/12 (13).webp'
import Hillary1214 from '../../../media/fasad/Hillary/12 (14).webp'
import Hillary1215 from '../../../media/fasad/Hillary/12 (15).webp'
import Hillary1216 from '../../../media/fasad/Hillary/12 (16).webp'

const Hillary12images =[
Hillary121,Hillary122,Hillary123,Hillary124,Hillary125,Hillary126,Hillary127,Hillary128,Hillary129,Hillary1210,Hillary1211,Hillary1212,Hillary1213,Hillary1214,Hillary1215,Hillary1216
]

export default Hillary12images;